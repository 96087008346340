<template>
  <panel-main ref="panelMainRef">
    <!-- 表格区域 -->
    <div class="table-content">
      <!-- 表格 -->
      <ml-table
        ref="tableRef"
        :tableData="tableData"
        :templateData="templateData"
        :pageParams="pageParams"
        tableHeight="100%"
        :useJumpClick="false"
        :templateAuths="{
          btnActive: ['normal_update', 'upload_version', 'normal_examine'],
        }"
      >
        <template #coverImage="{ row }">
          <div class="cover-img">
            <ml-image
              v-if="row.coverType"
              width="33px"
              height="33px"
              :src="row.coverImage"
              emptyType="custom"
              fit="contain"
              :customErrIcon="deviceDefaultImg"
              customErrIconWidth="33px"
              customErrIconHeight="33px"
              previewDefault
            ></ml-image>
            <div v-else class="none-img">暂无图片</div>
          </div>
        </template>

        <template #versionCode="{ row }">
          {{ row.versionCode ? "v" + row.versionCode : "-" }}
        </template>

        <template #upgradeType="{ row }">
          {{ $getOptsItem(row.upgradeType, versionType) || "-" }}
        </template>

        <template #btnActive="{ row }">
          <auth-button
            type="warning"
            size="small"
            menuCode="normal_update"
            @handleClick="jumpAction(row, 'edit')"
            >修改示意图</auth-button
          >
          <auth-button
            type="primary"
            size="small"
            menuCode="upload_version"
            @handleClick="openVersionDialog(row)"
            >上传新版本</auth-button
          >

          <auth-button
            type="primary"
            size="small"
            menuCode="normal_examine"
            @handleClick="jumpDetail(row)"
            >查看历史升级包</auth-button
          >
        </template>
      </ml-table>
    </div>
  </panel-main>

  <!-- 上传版本 弹窗 -->
  <tool-dialog
    ref="versionDialogRef"
    title="上传新版本"
    @confirm="versionSubmitForm"
  >
    <ml-form
      ref="bucketFormRef"
      label-position="top"
      className="hide-scrollbar file-type-form"
      :model="versionFormData"
      :rules="bucketFormRules"
    >
      <el-form-item label="版本号：" prop="versionCode">
        <ml-input
          placeholder="请输入版本号"
          v-model="versionFormData.versionCode"
        >
          <template #prepend>
            <span>v</span>
          </template>
        </ml-input>
      </el-form-item>
      <el-form-item label="版本类型：" prop="upgradeType">
        <el-radio-group v-model="versionFormData.upgradeType">
          <el-radio
            :label="item.value"
            size="large"
            v-for="(item, index) in versionType"
            :key="'item' + index"
            >{{ item.label }}</el-radio
          >
        </el-radio-group>
      </el-form-item>
      <el-form-item label="安装包文件：" prop="upgradeUrl">
        <div class="upload-files-box">
          <upload-file
            uploadText="安装包文件上传"
            v-model:fileList="fileList"
            folder="DEVICE_FIRMWARE"
            :uploadInterceptCall="uploadInterceptCall"
            accept=".bin"
            :disabled="!testVersionCode"
          ></upload-file>
          <div class="tips show-line-tips" v-if="!testVersionCode">
            需要先进行版本号的填写
          </div>
          <div class="tips show-line-tips" v-else>
            {{
              `温馨提示：请上传Bin格式的文件，正确命名安装包名称，如：${
                modelVersion ? modelVersion : "GBMS000000v0.0.1"
              }.bin，若核对不成功将无法上传安装包`
            }}
          </div>
        </div>
      </el-form-item>
      <el-form-item
        label="备注："
        prop="versionNote"
        class="storage-capacity-item"
      >
        <ml-input
          type="textarea"
          placeholder="请输入备注"
          v-model="versionFormData.versionNote"
        ></ml-input>
      </el-form-item>
    </ml-form>
  </tool-dialog>
</template>

<script setup>
/* 文件上传 */
import uploadFile from "@/components/upload-file.vue";
import {
  ref,
  onMounted,
  reactive,
  getCurrentInstance,
  computed,
  nextTick,
  watch,
} from "vue";

import { useTableParamsModule } from "@/utils/mixin";
import { versionOpts } from "@/utils/options";
import { versionNumberRul } from "@/utils/formRules";
import { versionNumber } from "@/utils/regExp";

const { proxy } = getCurrentInstance();

const props = defineProps({
  tabValue: {
    type: [String, Number],
    default: "",
  },
});

/* 图标 */
// 设备默认图片 本地
const deviceDefaultImg = require("@/assets/images/default-device.png");

/* 选型数据 */
// 版本类型
const versionType = versionOpts();

// 面板
// ref
const panelMainRef = ref();

// 表格配置
const {
  resetParams,
  tableData,
  pageParams,
  fetchFilterData,
  getTableList,
  openTableLoading,
  tableRef,
  filterParams,
} = useTableParamsModule({
  fetchTableName: "fetchGetDevicePageList",
});

// 表格模板数据
const templateData = [
  {
    type: "index",
    label: "序号",
    width: "120",
    align: "left",
  },
  {
    prop: "model",
    label: "设备型号",
    minWidth: "150",
    align: "left",
  },
  {
    prop: "coverImage",
    label: "设备型号示意图",
    minWidth: "150",
    showTemplate: true,
    align: "left",
  },
  {
    prop: "versionCode",
    label: "最新版本号",
    showTemplate: true,
    minWidth: "150",
    align: "left",
  },
  {
    prop: "upgradeType",
    label: "最新版本类型",
    showTemplate: true,
    minWidth: "150",
    align: "left",
  },
  {
    prop: "versionNote",
    label: "最新版本备注说明",
    minWidth: "240",
    align: "left",
  },
  {
    prop: "upgradeTime",
    label: "最新版本上传时间",
    minWidth: "170",
    align: "left",
  },
  {
    prop: "firmwareCount",
    label: "历史版本数量",
    minWidth: "150",
    align: "left",
  },
  {
    prop: "btnActive",
    label: "操作",
    showTemplate: true,
    align: "left",
    fixed: "right",
    minWidth: "310",
  },
];

// *********************************************************************************************************
// 表格数据操作  START
// *********************************************************************************************************

/* 用户弹窗 */
// ref
const versionDialogRef = ref();
const bucketFormRef = ref();

// 表单信息
const versionFormDataDefault = () => ({
  deviceId: "",
  upgradeUrl: "",
  versionCode: "",
  upgradeType: 1,
  versionNote: "",
});
const versionFormData = reactive(versionFormDataDefault());

const bucketFormRules = computed(() => {
  const rules = {
    upgradeUrl: [
      {
        required: true,
        trigger: "change",
        message: "请上传安装包",
      },
    ],
    upgradeType: [
      {
        required: true,
        trigger: "change",
        message: "请选择版本类型",
      },
    ],
    versionCode: versionNumberRul(),
  };

  return rules;
});

/* 上传的文件 */
const fileList = ref([]);
watch(
  () => fileList.value,
  (nVal) => {
    const [fileItem = {}] = nVal;
    versionFormData.upgradeUrl = fileItem.relUrl;
  },
  {
    deep: true,
  }
);

/* 判断型号与上传文件包名是否一致 */
const uploadInterceptCall = (files) => {
  // 判断文件名
  const { name } = files;
  // 分割 名称与后缀
  const nameAfters = name.split(/\.bin/)[0];
  const UpdateFileName = modelVersion.value == nameAfters;
  if (UpdateFileName) {
    return !UpdateFileName;
  } else {
    nextTick(() => {
      fileList.value = [];
    });
    proxy.$warningMsg(
      "请正确命名安装包名称，如：GBMS004E01(设备型号) + v1.45（版本号）.bin"
    );
    return !UpdateFileName;
  }
};

// 拼接型号和版本
const modelVersion = computed(() => {
  const { model } = chooseItem;
  const { versionCode } = versionFormData;
  if (model && versionCode) {
    return model + "v" + versionCode;
  }
});

// 判断版本号是否符合标准
const testVersionCode = computed(() => {
  const { versionCode } = versionFormData;
  const version = versionNumber(versionCode);
  return version;
});

// 选中的数据
const chooseItem = reactive({});

// 打开弹窗
const openVersionDialog = (data = {}) => {
  // 赋值
  Object.assign(chooseItem, { ...data });

  versionDialogRef.value.openDialog();

  nextTick(async () => {
    proxy.$updateParams(versionFormData, versionFormDataDefault());
    fileList.value = [];
    setTimeout(() => {
      bucketFormRef.value.clearValidate();
    });
  });
};

// 跳转设置页面
const jumpAction = (data = {}, actionType) => {
  const id = data.id || "";
  const params = { id, actionType };
  proxy.$navigateTo("/equipmentModelManagementAction", params);
};

// 跳转历史升级包
const jumpDetail = (data = {}) => {
  const deviceId = data.id || "";
  const model = data.model || "";
  const params = { deviceId, model };
  proxy.$navigateTo("/historyUpgradePackage", params);
};

// 表单提交
const versionSubmitForm = async (closeLoading) => {
  const testRes = await proxy.$testForm(closeLoading, bucketFormRef.value);
  if (!testRes) {
    return;
  }
  versionFormData.deviceId = chooseItem.id;
  let result = proxy.$removeDecontextAttr(versionFormData);
  try {
    await proxy.$storeDispatch("fetchUploadFirmware", result);
    proxy.$successMsg("上传成功");
    closeLoading();
    versionDialogRef.value.closeDialog();
    getTableList();
  } catch (error) {
    closeLoading();
  }
};

// *********************************************************************************************************
// 表格数据操作  END
// *********************************************************************************************************

// *********************************************************************************************************
// 查询 START
// *********************************************************************************************************

// *********************************************************************************************************
// 查询 END
// *********************************************************************************************************

defineExpose({
  resetParams,
  fetchFilterData,
  getTableList,
  openTableLoading,
  openVersionDialog,
});
</script>

<style lang="scss" scoped>
.table-content {
  height: 100%;

  // 示意图
  .cover-img {
    .custom-image {
      width: 33px;
      height: 33px;
      border-radius: 6px;
    }
  }

  // 项目成员

  // .head-sculpture-group {
  //   display: flex;
  //   .head-box {
  //     margin-right: 8px;
  //   }
  // }
}

.byte-group {
  width: 100%;
  display: flex;
  margin-top: 15px;
  position: relative;
  margin-bottom: 10px;
  .item {
    padding-left: 12px;

    &:first-child {
      padding-left: 0;
    }
  }
}
</style>

<style lang="scss">
.file-type-form {
  .storage-capacity-item {
    margin-bottom: 27px;
  }

  .tips {
    color: #f59a23;
  }
}
</style>
